.form-group{
    margin: 0 5px;
    flex: 1;
    position: relative;
    align-items: center;
    display: flex;

    input, select, button, app-select{
        width: 100%;
    }

    @include media(480){
        flex: 0 0 100%;
        margin: 0 0 10px 0;
    }
}

form.-submitted{
    .ng-invalid,
    input[type=checkbox].ng-invalid + .checkbox_square,
    input[type=radio].ng-invalid + .radio_circle,
    app-workshop-table.ng-invalid .workshop-table, app-workshop-table.ng-invalid .workshop-row,
    .multiple-datepicker.ng-invalid .multiple-datepicker_input,
    input[type=hidden].ng-invalid + app-select .select{
        border-color: $red;
    }

    .form-error-msg{
        display: inline-block;
    }

}

.form-error-msg{
    position: absolute;
    z-index: 10;
    font-size: 11px;
    background: $red;
    color: $white;
    padding: 5px 7px;
    box-shadow: 0 1px 3px rgba($dark, .2);
    margin-top: 4px;
    left: 0;
    bottom: 0;
    transform: translate(0, 100%);
    display: none;

    &:after{
        bottom: 100%;
        left: 10px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: $red;
        border-width: 4px;
    }

    &.-side{
        position: relative;
        transform: translate(0);
        margin: -5px 0 -5px 10px;

        &:after{
            right: 100%;
            top: 50%;
            bottom: auto;
            left: auto;
            border-bottom-color: transparent;
            border-right-color: $red;
            margin-top: -4px;
        }
    }
}

.form-line{
    display: flex;
    margin-bottom: 10px;
    margin-left: -5px;
    margin-right: -5px;

    @include media(480){
        flex-wrap: wrap;
        margin: 0;
    }
}

input, .input{
    border: 1px solid $gray;
    height: 36px;
    padding: 8px;
    font-size: em(13);
    font-family: $text-font;

    @include placeholder{
        color: $placeholder-color;
    }

    &.-large{
        height: 48px;
        padding-left: 25px;
        padding-right: 25px;
    }
}

select{
    height: 36px;
    padding: 8px;
    border: 1px solid $gray;
    background: $white;
}

/*--------------------------------------/
 datepicker
--------------------------------------*/

.form-group{

    &.-column{
        flex-direction: column;

        .checkbox + .form-error-msg{
            align-self: flex-start;
        }
    }

    ng-datepicker{
        display: block;
        position: relative;
        z-index: 1;
    }

    .ngx-datepicker-container:before{
        content: '';
        border-style: solid;
        border-width: 6px 5px 0 5px;
        border-color: #000 transparent transparent transparent;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translate(0, -50%);

    }

    .ngx-datepicker-container, .ngx-datepicker-input{
        width: 100% !important;
    }
}

/*--------------------------------------/
 textarea
--------------------------------------*/

textarea{
    resize: none;
    width: 100%;
    border: 1px solid $gray;
    padding: 8px;
    font-size: em(13);
    font-family: $text-font;

    @include placeholder{
        color: $placeholder-color;
    }
}

/*--------------------------------------/
 calendar
--------------------------------------*/
.multiple-datepicker{
    position: relative;
}


.form-group .mat-icon-button{
    height: 36px;
    width: 36px;
    line-height: 36px;
}

.mat-button-focus-overlay{
    background: none !important;
}

.form-group mat-datepicker-toggle{
    position: absolute;
    right: 0;
    top: 0;
}