@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/*--------------------------------------/
 core
--------------------------------------*/
@import "assets/styles/core/reset";
@import "assets/styles/core/media";
@import "assets/styles/core/variables";
@import "assets/styles/core/mixins";
@import "assets/styles/core/functions";
@import "assets/styles/core/helpers";

/*--------------------------------------/
 Base
--------------------------------------*/
@import "assets/styles/base/fonts";
@import "assets/styles/base/main";
@import "assets/styles/base/typography";
@import "assets/styles/base/zindex";


/*--------------------------------------/
 Elements
--------------------------------------*/
@import "assets/styles/elements/button";
@import "assets/styles/elements/checkbox";
@import "assets/styles/elements/radio";
@import "assets/styles/elements/form";
@import "assets/styles/elements/select";

