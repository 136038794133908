html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section{
    display: block;
}

html, body{
    height: 100%;
}

body{
    line-height: 1;
}

ol, ul{
    list-style: none;
}

li{
    list-style: none;
}

blockquote, q{
    quotes: none;
}

a{
    text-decoration: none;
    color: inherit;
}

a:focus{
    outline: none;
}

strong, b{
    font-weight: bold;
}

em{
    font-style: italic;
}

blockquote:before, blockquote:after,
q:before, q:after{
    content: '';
    content: none;
}

table{
    border-collapse: collapse;
    border-spacing: 0;
}

input:focus, select:focus, button:focus, textarea:focus{
    outline: none;
}

input, select, button, textarea, form{
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    margin: 0;
}

input[type=button], input[type=submit], button{
    cursor: pointer;
}

input[type=text]::-ms-clear{
    display: none;
    width: 0;
    height: 0;
}

input[type=text]::-ms-reveal{
    display: none;
    width: 0;
    height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration{
    display: none;
}

*, *:before, *:after{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}