.checkbox{
    cursor: pointer;
    font-size: em(13);
    display: inline-flex;
    align-items: center;

    .form-group &{
        margin: 10px 0 0;
        width: 100%;
    }

    & + .form-error-msg{
        margin-top: 5px;
        position: relative;
        transform: none;

        &:after{
            left: 4px;
        }
    }

    span{
        line-height: 1.3;
    }

    input[type=checkbox]{
        display: none;
    }

    &_square{
        border: 1px solid $dark;
        width: 17px;
        height: 17px;
        display: inline-block;
        position: relative;
        margin-right: 1em;
        min-width: 17px;
        min-height: 17px;

        &:before, &:after{
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            background: $blue;
            opacity: 0;
            transition: opacity .1s linear;
        }

        &:after{
            height: 11px;
            width: 2px;
            transform: rotate(40deg);
            top: 2px;
            left: 8px;
        }

        &:before{
            height: 5px;
            width: 2px;
            transform: rotate(-45deg);
            top: 6px;
            left: 3px;
        }
    }
    a{
        @include underline();
        padding-bottom: 1px;
    }

    input[type=checkbox]:checked + .checkbox_square{
        &:before, &:after{
            opacity: 1;
        }
    }
}
