@charset "UTF-8";

@font-face {
    font-family: '3M Circular TT';
    src: url($font-path + '/3MCircular/3MCircularTT-LightItalic.eot');
    src: url($font-path + '/3MCircular/3MCircularTT-LightItalic.eot?#iefix') format('embedded-opentype'),
    url($font-path + '/3MCircular/3MCircularTT-LightItalic.woff2') format('woff2'),
    url($font-path + '/3MCircular/3MCircularTT-LightItalic.woff') format('woff'),
    url($font-path + '/3MCircular/3MCircularTT-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: '3M Circular TT';
    src: url($font-path + '/3MCircular/3MCircularTT-Light.eot');
    src: url($font-path + '/3MCircular/3MCircularTT-Light.eot?#iefix') format('embedded-opentype'),
    url($font-path + '/3MCircular/3MCircularTT-Light.woff2') format('woff2'),
    url($font-path + '/3MCircular/3MCircularTT-Light.woff') format('woff'),
    url($font-path + '/3MCircular/3MCircularTT-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: '3M Circular TT';
    src: url($font-path + '/3MCircular/3MCircularTT-BoldItalic.eot');
    src: url($font-path + '/3MCircular/3MCircularTT-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url($font-path + '/3MCircular/3MCircularTT-BoldItalic.woff2') format('woff2'),
    url($font-path + '/3MCircular/3MCircularTT-BoldItalic.woff') format('woff'),
    url($font-path + '/3MCircular/3MCircularTT-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: '3M Circular TT';
    src: url($font-path + '/3MCircular/3MCircularTT-Bold.eot');
    src: url($font-path + '/3MCircular/3MCircularTT-Bold.eot?#iefix') format('embedded-opentype'),
    url($font-path + '/3MCircular/3MCircularTT-Bold.woff2') format('woff2'),
    url($font-path + '/3MCircular/3MCircularTT-Bold.woff') format('woff'),
    url($font-path + '/3MCircular/3MCircularTT-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: '3M Circular TT';
    src: url($font-path + '/3MCircular/3MCircularTT-Book.eot');
    src: url($font-path + '/3MCircular/3MCircularTT-Book.eot?#iefix') format('embedded-opentype'),
    url($font-path + '/3MCircular/3MCircularTT-Book.woff2') format('woff2'),
    url($font-path + '/3MCircular/3MCircularTT-Book.woff') format('woff'),
    url($font-path + '/3MCircular/3MCircularTT-Book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: '3M Circular TT';
    src: url($font-path + '/3MCircular/3MCircularTT-BookItalic.eot');
    src: url($font-path + '/3MCircular/3MCircularTT-BookItalic.eot?#iefix') format('embedded-opentype'),
    url($font-path + '/3MCircular/3MCircularTT-BookItalic.woff2') format('woff2'),
    url($font-path + '/3MCircular/3MCircularTT-BookItalic.woff') format('woff'),
    url($font-path + '/3MCircular/3MCircularTT-BookItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
