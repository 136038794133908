.radio{
    cursor: pointer;
    font-size: em(13);
    display: inline-flex;
    align-items: center;
    margin-right: 20px;

    & + .form-error-msg.-side{
        transform: translate(0, -3px);
        margin-top: 5px;
    }

    input[type=radio]{
        display: none;
    }

    &_circle{
        border: 1px solid rgba($dark, .7);
        width: 18px;
        height: 18px;
        display: inline-block;
        position: relative;
        margin-right: 1em;
        border-radius: 50%;

        &:before{
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            background: $blue;
            opacity: 0;
            transition: opacity .1s linear;
            height: 8px;
            width: 8px;
            transform: translate(-50%, -50%);
            border-radius: 50%;
        }
    }

    &_value{
        margin-top: 3px;
    }

    input[type=radio]:checked + .radio_circle{
        &:before, &:after{
            opacity: 1;
        }
    }
}