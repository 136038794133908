.hide{
    display: none !important;
}

.word-break{
    word-break: break-all;
}

.float-right{
    float: right;
}