body{
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-family: $text-font;
    font-size: $base-font;
    color: $base-color;
    background: url($image-path + '/main-bg.jpg');
    background-size: 1920px;
    background-position: center;
    background-attachment: fixed;

}

.container{
    width: 1040px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    @include media(480){
        padding: 0 10px;
    }
}

.text-content{
    @include text-content();

    @include media(480){
        font-size: 14px;
    }
}

.image-content {
    margin-bottom: 10px;

    &.-sidebar {
        max-width: 290px;
    }

    img {
        max-width: 100%;
    }
}

.empty-text{
    margin: 1em 0;
}

.main-title{
    background: $orange;
    padding: 30px 0;
    background: url($image-path + '/title-bg.png');
    background-size: auto 100%;
    background-position: center;
    background-repeat: repeat;
    margin-bottom: 60px;
    line-height: 1.3;
    margin-top: -4px;

    .heading{
        margin-left: 1.7em;

        @include media(480){
            margin-left: 0;
        }
    }

    @include media(860){
        font-size: 14px;
    }

    @include media(640){
        font-size: 13px;
    }

    @include media(480){
        font-size: 12px;
    }
}

.main-content{
    margin-top: 60px;

    .container{

        &.-flex{
            display: flex;
        }

        @include media(860){
            flex-direction: row;
            flex-wrap: wrap;
        }

        aside[app-sidebar]{
            flex: 1;

            @include media(860){
                margin-left: 0;
                flex-basis: 100%;
                max-width: 100%;
            }
        }

        main[app-content]{
            flex: 2;
            margin-left: 70px;

            @include media(1024){
                margin-left: 40px;
            }

            @include media(860){
                margin-left: 0;
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }
}

app-workshop-table{
    display: block;
    width: 100%;
}

hr{
    border: 1px solid $gray;
    border-bottom: none;
}
