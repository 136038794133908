
app-select{
    .select-option.-hover{
        background: $g-orange-yellow;
        color: $dark;
    }

    .select-choice-option{
        padding: 5px 8px;
        background: $blue;
        color: $white;
    }

    .select-choice-option_close:after, .select-choice-option_close:before{
        background: $white;
    }

    .select-dropdown_item.-selected{
        background: #ebebeb;
    }

    .select{
        &_input, &_input:disabled{
            color: #757575;
            -webkit-text-fill-color: #757575;
            font-size: em(13);

            &.-selected{
                -webkit-text-fill-color: $dark;
                color: $dark;
            }
        }
    }
}


