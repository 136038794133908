@mixin overlay{
    position: absolute;
    left: 0;
    width: 100%;
    right: 0;
    height: 100%;
}

@mixin placeholder{
    &::-webkit-input-placeholder{
        @content
    }
    &:-moz-placeholder{
        @content
    }
    &::-moz-placeholder{
        @content
    }
    &:-ms-input-placeholder{
        @content
    }
}

@mixin underline-hover($height: 1px, $color: inherit){
    position: relative;
    display: inline-block;

    &:before{
        content: '';
        position: absolute;
        height: 0;
        color: $color;
        border-bottom: $height solid;
        width: 0;
        bottom: 0;
        left: 50%;
        transition: all .3s linear;
        opacity: 1;
    }

    &:hover:before{
        width: 100%;
        left: 0;
        opacity: 1;
    }
}

@mixin double-underline-hover($height: 1px, $color: inherit, $padding-right: false){
    position: relative;
    display: inline-block;

    &:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        color: $color;
        border-bottom: $height solid;
        opacity: 0.4;
        transition: width .5s;
    }

    &:after{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        color: $color;
        border-bottom: $height solid;
        transition: width .5s;
        transition-delay: .3s;
    }

    &:hover{

        &:before{
            width: 100%;
        }

        &:after{
            width: 100%;
        }
    }
}

@mixin underline-out($height, $color){

    &:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: $height;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    &:after{
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: $height;
        width: 0;
        background: $color;
        transition: width .5s ease;
    }

    &:hover{

        &:before{
            width: 100%;
            background: $color;
            transition: width .5s ease;
        }

        &:after{
            width: 100%;
            background: transparent;
            transition: all 0s ease;
        }
    }
}

@mixin underline($height: 1px, $color: inherit){
    position: relative;
    display: inline-block;

    &:after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: $height solid;
    }

    &:hover:after{
        animation: underline-slide .6s linear;
    }

    @keyframes underline-slide{
        0%{
            left: 0;
        }

        50%{
            left: 100%;
            right: 0;
        }

        51%{
            left: 0;
            right: 100%;
        }

        100%{
            right: 0;
        }
    }
}

@mixin hide-scroll{
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar{
        display: none;
    }
}

@mixin clearfix{
    *zoom: 1;

    &:before, &:after{
        content: '';
        display: table;
    }

    &:after{
        clear: both;
    }

}

@mixin ie-hack{
    //ie edge
    @supports (-ms-ime-align:auto){
        @content;
    }

    //ie 10+
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
        @content;
    }
}

@mixin overlay{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

@mixin aspect-ratio($width, $height){
    position: relative;
    &:before{
        content: '';
        display: block;
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > .ratio-content{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin text-content($fz: $base-font, $lh: 1.7){
    font-size: $fz;
    line-height: $lh;

    ul{
        list-style: disc;
        padding-left: 2em;
        margin-top: .5em;
        margin-bottom: .5em;

        li{
            list-style: disc;
        }
    }

    ol{
        list-style: decimal;
        padding-left: 1.3em;
        margin-top: .5em;
        margin-bottom: .5em;

        li{
            list-style: decimal;
        }
    }

    strong, b{
        font-weight: bold;
    }

    i, em, blockquote{
        font-style: italic;
    }

    blockquote{
        border-left: 3px solid rgba($base-color, .7);
        background: rgba(#000, .05);
        padding: 2px 0 2px 20px;
    }

    table{
        width: 100%;
        text-align: left;

        th{
            font-weight: 600;
            border-bottom: 1px solid rgba($base-color, .7);
        }
    }

    p{
        margin-bottom: .5em;
    }

    a{
        color: $dark;
        cursor: pointer;
        font-weight: bold;
        text-decoration: underline;
    }

    h1, h2, h3, h4, h5, h6{
        font-family: $heading-font;
        font-weight: 600;
        margin: .2em 0;
        line-height: 1.2;
    }

    h1{
        font-size: 2em;
    }

    h2{
        font-size: 1.6em;
    }

    h3{
        font-size: 1.3em;
    }

    h4{
        font-size: 1.1em;
    }

    h5{
        font-size: 1em;
    }

    h6{
        font-size: .8em;
    }

    img{
        max-width: 100%;
        height: auto;
    }

    img.alignleft{
        float: left;
        margin-right: 25px;
    }

    img.alignright{
        float: right;
        margin-left: 25px;
    }
}

