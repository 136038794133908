.heading{
    font-family: $heading-font;
    font-weight: bold;
    line-height: 1.4;

    &.-h1{
        font-size: em(30);
        font-weight: normal;
    }

    &.-h2{
        font-size: em(25);
    }

    &.-h3{
        font-size: em(21);
    }

    &.-h4{
        font-size: em(18);
    }

    &.-h5{
        font-size: em(16);
        margin-bottom: 1em;
    }

    &.-h6{
        font-size: em(13);
        margin-bottom: 1em;
    }
}
